'use client';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// components
import Stack from '@mui/material/Stack';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 0, 1, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(9, 0, 0, 0),
  },
  zoom: 0.9,
}));

// ----------------------------------------------------------------------

export default function TechJoinCompanies() {
  return (
    <RootStyle>
      <Container sx={{ overflow: 'hidden' }}>
        <Stack>
          <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column', maxWidth: 900, margin: 'auto' }}>
            <Typography variant='h2' sx={{ mt: 2, mb: { xs: 2, md: 3 }, lineHeight: 1.2 }}>
              Join 1,000+ companies that <br className='desktop' />track smarter with Tracklution
            </Typography>
            <Typography sx={{ color: 'text.primary', mb: { xs: 4, md: 5 } }}>
              {'We\'re solving tracking challenges across diverse industries and company sizes. Whether you\'re a small business, a large corporation or an agency, we offer tailored solutions to support your success at every stage of growth.'}
            </Typography>
          </Box>
          <Stack direction='row' justifyContent='space-evenly' sx={{ flexWrap: 'wrap' }}>
            <Stack alignItems='center' sx={{ minWidth: 300, mb: { xs: 4, md: 4 } }}>
              <Typography variant='h1' color='accent.main' gutterBottom>
                +34.2 %
              </Typography>
              <Typography variant='h5' sx={{ maxWidth: 325, textAlign: 'center', lineHeight: 1.3 }}>
                Additional conversions reported with Tracklution on average.
              </Typography>
            </Stack>
            <Stack alignItems='center' sx={{ minWidth: 300, mb: { xs: 4, md: 4 } }}>
              <Typography variant='h1' color='accent.main' gutterBottom>
                70,000,000+
              </Typography>
              <Typography variant='h5' sx={{ maxWidth: 325, textAlign: 'center', lineHeight: 1.3 }}>
                Events processed<br />every day
              </Typography>
            </Stack>
            <Stack alignItems='center' sx={{ minWidth: 300, mb: { xs: 4, md: 4 } }}>
              <Typography variant='h1' color='accent.main' gutterBottom>
                10B+ €
              </Typography>
              <Typography variant='h5' sx={{ maxWidth: 325, textAlign: 'center', lineHeight: 1.3 }}>
                Total reported enhanced conversions
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </RootStyle>
  );
}
