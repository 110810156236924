'use client';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Grid, Hidden } from '@mui/material';
// components
import Stack from '@mui/material/Stack';
import Iconify from 'src/components/iconify';
import HeroWideAnimation from 'src/components/landing/HeroWideAnimation';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(5, 0, 4, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(9, 0, 8, 0),
  },
  zoom: 0.9,
}));

// ----------------------------------------------------------------------

export default function TechTrackingPixelsOnSteroids() {
  return (
    <RootStyle>
      <Container sx={{ overflow: 'hidden' }} maxWidth='xl'>
        <Stack sx={{ px: { md: 8 } }}>
          <Box sx={{ textAlign: { xs: 'left', md: 'center' }, display: 'flex', flexDirection: 'column', mb: { md: 3 } }}>
            <Typography variant='h5' sx={{ mt: 2, mb: 0.75, zoom: 1.111 }} color='accent.main'>
              Tracking pixels on steroids
            </Typography>
            <Typography variant='h2' sx={{ mb: { xs: 2, md: 3 }, lineHeight: 1.2 }}>
              Server-Side Tracking with a Simple Pixel Setup
            </Typography>
            <Typography sx={{ color: 'text.primary', mb: { xs: 5, md: 5 } }} variant='body1'>
              Enable 1st-party cookies, server-side tracking and Conversion APIs of ad platforms <br className='desktop' /> with a simple tracking
              pixel setup that every digital marketer is familiar with.
            </Typography>
          </Box>
          <Grid container spacing={{ xs: 0, md: 8 }}>
            <Hidden mdDown>
              <Grid item>
                <HeroWideAnimation />
              </Grid>
            </Hidden>
            <Grid item md={4} sx={{ mb: 4 }}>
              <Typography variant='h4' sx={{ mb: 3, lineHeight: 1.15 }}>
                Drive traffic to your site
              </Typography>
              <Stack spacing={1.5}>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>Get started with Tracklution by installing a simple tracking script on your
                    site</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>Activate new ad platforms with just a few clicks</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>No recurring tracking pixels, no API building, no server management = saved developer
                    resources</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item md={4} sx={{ mb: 4 }}>
              <Typography variant='h4' sx={{ mb: 3, lineHeight: 1.15 }}>
                Gather first-party data
              </Typography>
              <Stack spacing={1.5}>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>Track event with {'Tracklution\'s'} first-party pixels</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>Improves tracking accuracy, protects {'users\''} privacy and makes your site load
                    faster</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>You can also track conversions happening offline or in other systems, such as CRM or
                    booking system</Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item md={4} sx={{ mb: 4 }}>
              <Typography variant='h4' sx={{ mb: 3, lineHeight: 1.15 }}>
                Send conversions to ad platforms
              </Typography>
              <Stack spacing={1.5}>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>Tracklution increases conversions sent to ad platforms on average by +34.2%, combining
                    pixels and server-side APIs, for increased advertising efficiency</Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='start'>
                  <Iconify icon='solar:check-circle-linear' width={26} color='accent.main' />
                  <Typography sx={{ ml: 0.5, lineHeight: 1.6 }}>Simply the best tool to tackle cookie deprecation and server-side tracking - GDPR
                    compliant, of course</Typography>
                </Stack>
              </Stack>
            </Grid>

          </Grid>
        </Stack>
      </Container>
    </RootStyle>
  );
}
