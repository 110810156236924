'use client';

// icons
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button, Container, Typography, Grid, Link as MuiLink } from '@mui/material';
// hooks
// routes
// components
import { useSignupContext } from 'src/components/signup-popup/context/signup-context';
import HeroDesktopAnimation from 'src/components/landing/HeroDesktopAnimation';
import PropTypes from 'prop-types';
import Iconify from 'src/components/iconify';
import TechReferenceLogos from './TechReferenceLogos';
import { HEADER_DESKTOP_HEIGHT } from '../../../config';

// ----------------------------------------------------------------------

// const captionStyle = {
//   sm: { fontSize: { xs: '1.05rem', md: '1.15rem', xl: '1.25rem' }, fontWeight: 500, lineHeight: 1.2 },
//   md: { fontSize: { xs: '1.1rem', md: '1.20rem', xl: '1.25rem' }, fontWeight: 600, lineHeight: 1.3 },
//   lg: { fontSize: { xs: '1.25rem', md: '1.25rem', xl: '1.25rem' }, fontWeight: 600, lineHeight: 1.3 },
// };

const titleStyle = {
  sm: {
    fontSize: { xs: '2.3rem', md: '3.5rem', xl: '4.2rem' },
    lineHeight: { xs: 1.1, lg: 1.1 },
    fontWeight: 700,
  },
  md: {
    fontSize: { xs: '2.8rem', md: '3.6rem', xl: '4.2rem' },
    lineHeight: { xs: 1, lg: 1.1 },
    fontWeight: 700,
  },
  lg: {
    fontSize: { xs: '3.3rem', md: '4rem', xl: '5rem' },
    lineHeight: { xs: 1.1, lg: 1 },
    fontWeight: 700,
  },
};

const descriptionStyle = {
  md: { fontSize: { xs: '1rem', md: '1.1rem', xl: '1.2rem' }, fontWeight: 400, lineHeight: { xs: 1.5, md: 1.6 } },
  lg: { fontSize: { xs: '1.05rem', md: '1.1rem', xl: '1.2rem' }, fontWeight: 400 },
};

const featureStyle = {
  md: { fontSize: { xs: '1rem', md: '1.1rem', xl: '1.2rem' }, fontWeight: 400, lineHeight: 1.5 },
  lg: { fontSize: { xs: '1.05rem', md: '1.1rem', xl: '1.2rem' }, fontWeight: 400, lineHeight: { xs: 1.6, md: 1.15 }, my: -0.5 },
};

const buttonLabelStyle = {
  md: { px: { xs: 1.5, md: 8 }, py: { xs: 2.5, md: 4 }, fontSize: { xs: '1rem', md: '1.3rem' } },
  lg: { px: { xs: 2, md: 8 }, py: { xs: 3.5, md: 4 }, fontSize: { xs: '1.1rem', md: '1.3rem' } },
};

// const buttonDescriptionStyle = {
//   md: { fontSize: { xs: '0.975rem', md: '1rem' }, lineHeight: 1.5 },
//   lg: { lineHeight: 1.6 },
// };

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(0, 0, 0, 0),
  [theme.breakpoints.up('md')]: {
    minHeight: `calc(100vh - ${HEADER_DESKTOP_HEIGHT}px)`,
  },
}));

const getContainerMaxWidth = (maxWidth) => {
  if (maxWidth === 'xl') {
    return 'xl';
  }
  if (maxWidth === 'lg') {
    return 'xl';
  }
  if (maxWidth === 'md') {
    return 'lg';
  }
  return 'lg';
};

const getContentPl = (maxWidth) => {
  if (maxWidth === 'xl') {
    return { lg: 12 };
  }
  if (maxWidth === 'lg') {
    return { lg: 12, xl: 18 };
  }
  return {};
};
const getContentMr = (maxWidth) => {
  if (maxWidth === 'xl') {
    return { lg: -16 };
  }
  if (maxWidth === 'lg') {
    return { lg: 0 };
  }
  return {};
};

// ----------------------------------------------------------------------

export default function TechHero(
  {
    maxWidth = 'md',
    contentColsMd = 5,
    pt = 0,
    pb = 0,
    spacingXs = 4,
    spacingMd = 4,
    caption,
    captionSize = 'lg',
    captionColor = 'accent.main',
    title,
    titleSize = 'lg',
    description,
    descriptionSize = 'lg',
    features,
    featureSize = 'lg',
    featureMinWidth = 1,
    buttonLabel,
    buttonLabelSize = 'lg',
    buttonDescription,
    buttonDescriptionSize = 'lg',
    demoButtonVisible = true,
    imageMt = {},
    imageMb = {},
    imageMr = {},
    imageMl = {},
    children,
  },
) {

  const signup = useSignupContext();


  return (
    <RootStyle>
      <Container sx={{ zoom: 0.9, height: 1, display: 'flex', flexGrow: 1, alignItems: 'center', pr: { md: 0 } }} maxWidth={getContainerMaxWidth(maxWidth)}>
        <Grid container columnSpacing={3} alignItems='center' sx={{ height: 1, position: 'relative', mb: { lg: 11 } }}>
          <Grid item xs={12} md={contentColsMd} sx={{ zIndex: 1, position: 'relative' }}>
            <Stack spacing={{ xs: spacingXs, md: spacingMd }}
                   sx={{ pt, pb, pl: getContentPl(maxWidth), mr: getContentMr(maxWidth) }}>
              <Stack spacing={2}>
                {/* <Typography color={captionColor} sx={captionStyle[captionSize] ?? captionStyle.lg}> */}
                <Typography color={captionColor} variant='h5'>
                  {caption}
                </Typography>
                <Typography variant='h1' sx={titleStyle[titleSize] ?? titleStyle.lg}>
                  {title}
                </Typography>
              </Stack>

              <Stack spacing={2}>
                {description && <Typography color='text.primary' sx={descriptionStyle[descriptionSize] ?? descriptionStyle.lg}>
                  {description}
                </Typography>}

                {features && <Stack spacing={2} direction='row' sx={{ flexWrap: 'wrap', py: 1 }}>
                  {features.map((feature, key) =>
                    <Stack direction='row' spacing={1} key={key} alignItems='center'>
                      <Iconify icon='solar:check-circle-bold' width={24} sx={{ ml: -0.5 }} color='accent.main' />
                      <Typography color='text.primary'
                                  sx={{ ...(featureStyle[featureSize] ?? featureStyle.lg), minWidth: featureMinWidth }}>{feature}</Typography>
                    </Stack>,
                  )}
                </Stack>}
              </Stack>

              <Stack spacing={3} alignItems={{ xs: 'inherit', lg: 'center' }} direction={{ xs: 'column', lg: 'row' }}>
                <Button
                  color='inherit'
                  size='large'
                  variant='contained'
                  onClick={() => signup.onToggle('register', '/start')}
                  endIcon={<Iconify icon='carbon:chevron-right' />}
                  rel='noopener'
                  sx={{ ...(buttonLabelStyle[buttonLabelSize] ?? buttonLabelStyle.lg), textTransform: 'none', maxWidth: 400 }}
                >
                  {buttonLabel}
                </Button>{demoButtonVisible && <Box spacing={1.5} sx={{ lineHeight: 1.6 }}>
                {buttonDescription} Still in doubt? <br className='desktop' />
                {' '}
                <MuiLink
                  onClick={() => signup.onToggle('contact')}
                  rel='noopener'
                  sx={{ textDecoration: 'underline', color: 'text.primary', fontWeight: 600, cursor: 'pointer' }}
                >
                  Request a demo
                </MuiLink>.
              </Box>}
              </Stack>
            </Stack>
            {children}
          </Grid>
          <Grid item xs={12} md={12 - contentColsMd}>
            <Box mt={imageMt} mb={imageMb} mr={imageMr} ml={imageMl}>
              <HeroDesktopAnimation />
            </Box>
          </Grid>
        </Grid>
      </Container>

      <TechReferenceLogos />
    </RootStyle>
  );
}

TechHero.propTypes = {
  maxWidth: PropTypes.string,
  contentColsMd: PropTypes.number,
  pt: PropTypes.any,
  pb: PropTypes.any,
  spacingXs: PropTypes.number,
  spacingMd: PropTypes.number,
  caption: PropTypes.string,
  captionSize: PropTypes.string,
  captionColor: PropTypes.string,
  title: PropTypes.any,
  titleSize: PropTypes.string,
  description: PropTypes.any,
  descriptionSize: PropTypes.string,
  features: PropTypes.array,
  featureSize: PropTypes.string,
  featureMinWidth: PropTypes.number,
  buttonLabel: PropTypes.string,
  buttonLabelSize: PropTypes.string,
  buttonDescription: PropTypes.any,
  buttonDescriptionSize: PropTypes.string,
  demoButtonVisible: PropTypes.bool,
  imageMt: PropTypes.object,
  imageMr: PropTypes.object,
  imageMb: PropTypes.object,
  imageMl: PropTypes.object,
  children: PropTypes.node,
};
