'use client';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Grid, Dialog } from '@mui/material';
// components
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import Image from 'src/components/image';
import { fireWatchDemo, fireWatchDemoCompleted, fireWatchDemoPartial } from 'src/utils/tracking';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  background: `linear-gradient(${theme.palette.background.paper} 50%, ${theme.palette.background.neutral} 100%)`,
  padding: theme.spacing(5, 0, 0, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(9, 0, 0, 0),
  },
  zoom: 0.9,
}));

const videoWatchedPartialSeconds = 60;
const videoWatchedCompleteSeconds = 180;

let videoWatchedPartialTimer = null;
let videoWatchedCompleteTimer = null;

// ----------------------------------------------------------------------

export default function TechEarnMore() {
  const [openVideo, setOpenVideo] = useState(false);

  const handleOpenVideo = () => {
    setOpenVideo(true);
    fireWatchDemo();

    clearTimeout(videoWatchedPartialTimer);
    videoWatchedPartialTimer = setTimeout(() => {
      fireWatchDemoPartial();
    }, 1000 * videoWatchedPartialSeconds);

    clearTimeout(videoWatchedCompleteTimer);
    videoWatchedCompleteTimer = setTimeout(() => {
      fireWatchDemoCompleted();
    }, 1000 * videoWatchedCompleteSeconds);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
    clearTimeout(videoWatchedPartialTimer);
    clearTimeout(videoWatchedCompleteTimer);
  };

  return (
    <>
      <RootStyle>
        <Container sx={{ overflow: 'hidden' }} maxWidth='xl'>
          <Stack sx={{ px: { md: 8 } }}>
            <Box sx={{
              textAlign: { xs: 'left', md: 'center' },
              display: 'flex',
              flexDirection: 'column',
              maxWidth: 900,
              margin: 'auto',
              mb: { xs: 4.5, md: 6 },
            }}>
              <Typography variant='h5' sx={{ mt: 2, mb: 0.75, zoom: 1.111 }}>
                {'Don\'t Let Dead Cookies Kill Your Data'}
              </Typography>
              <Typography variant='h2' sx={{ lineHeight: 1.2 }}>
                Earn More by <Box component='span' color='accent.main'>Tracking Smarter</Box>
              </Typography>
            </Box>
            <Grid container spacing={{ xs: 0, md: 8 }}>
              <Grid item md={4} sx={{ mb: 5 }}>
                <Typography variant='h3' color='accent.main' sx={{ mb: { xs: 2, md: 3 }, lineHeight: 1.15 }}>
                  Improve ROAS
                </Typography>
                <Typography variant='body1'>
                  Client-side tracking pixels are already losing data. With Tracklution you deliver better and more data to ad platforms - on average
                  +34.2% more compared to traditional tracking pixels - to improve your results and get more out of your ad spend.
                </Typography>
              </Grid>
              <Grid item md={4} sx={{ mb: 4 }}>
                <Typography variant='h3' color='accent.main' sx={{ mb: { xs: 2, md: 3 }, lineHeight: 1.15 }}>
                  Adapt to cookie deprecation
                </Typography>
                <Typography variant='body1'>
                  Client-side cookies are facing limitations, but with Tracklution you {'don\'t'} have to worry. With us you always comply with the
                  most
                  recent tracking requirements. We handle the difficult parts in tracking, while you focus on growing your business and reaching your
                  goals.
                </Typography>
              </Grid>
              <Grid item md={4} sx={{ mb: 4 }}>
                <Typography variant='h3' color='accent.main' sx={{ mb: { xs: 2, md: 3 }, lineHeight: 1.15 }}>
                  95 % less cost
                </Typography>
                <Typography variant='body1'>
                  Forget costly development projects and maintenance work associated with server-side tracking. With Tracklution you get started
                  effortlessly in a matter of minutes, not months, with only of fraction of the price compared to traditional server-side tracking
                  solutions.
                </Typography>
              </Grid>
              <Stack sx={{ width: { xs: 'calc(100% + 4px)', md: '100%', cursor: 'pointer' }, mt: 2, pl: { xs: 0, md: 3 }, ml: { xs: -0.6, md: 0 } }}>
                <Image src='/assets/images/hero/tracklution-2024-dashboard-screenshot-watch-video.png' onClick={handleOpenVideo}
                       sx={{ width: { xs: 'calc(100% + 4px)', md: '100%' }, maxWidth: 1080, mx: 'auto', '&:hover': { opacity: 0.95 } }} />
              </Stack>
            </Grid>
          </Stack>
        </Container>
      </RootStyle>

      <Dialog fullWidth maxWidth='lg' open={openVideo} onClose={handleCloseVideo} PaperProps={{
        sx: { background: '#000', overflow: 'hidden', aspectRatio: 16 / 9, m: 0, width: 'calc(100% - 16px)' },
      }}>
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            src='https://player.vimeo.com/video/923703853?autoplay=1&loop=1&badge=0&sidedock=0&controls=1&title=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
            frameBorder='0'
            allow='autoplay; fullscreen; picture-in-picture; clipboard-write'
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            title='Install Server Side Tracking in few minutes with Tracklution'
          />
        </div>
      </Dialog>
    </>
  );
}
