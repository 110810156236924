'use client';

import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useRef } from 'react';
// next
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Container, Stack, Hidden, Typography } from '@mui/material';
// routes
// utils
import cssStyles from 'src/utils/cssStyles';
// components
import { CarouselDots, CarouselArrows } from 'src/components/landing';
import SocialIcon from 'src/components/iconify/social-icon';
import { useSignupContext } from 'src/components/signup-popup/context/signup-context';
import Iconify from 'src/components/iconify';
import Button from '@mui/material/Button';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  background: 'black',
  position: 'relative',
  padding: theme.spacing(6, 0, 10, 0),
  ...cssStyles(theme).bgImage(),
  '& .slick-list': {
    borderRadius: Number(theme.shape.borderRadius) * 2,
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(10, 0, 14, 0),
  },
  zoom: 0.9
}));

// ----------------------------------------------------------------------

TechConnectors.propTypes = {
  connectors: PropTypes.array.isRequired,
};

export default function TechConnectors({ connectors }) {
  const theme = useTheme();

  // const [selected, setSelected] = useState(0);
  const carouselRef = useRef(null);

  const carouselSettings = {
    dots: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    adaptiveHeight: true,
    rtl: Boolean(theme.direction === 'rtl'),
    // beforeChange: (current, next) => setSelected(next),
    ...CarouselDots({
      rounded: true,
      sx: { mt: 5 },
    }),
    responsive: [
      {
        breakpoint: theme.breakpoints.values.xl,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: theme.breakpoints.values.lg,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: theme.breakpoints.values.md,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <RootStyle>
      <Container sx={{ position: 'relative', zIndex: 9 }}>
        <Stack>
          <Stack>
            <Typography variant='h2' sx={{ mb: 1, color: 'accent.main', textAlign: 'center' }}>
              Explore the Connectors
            </Typography>
            <Typography sx={{ mb: 3, color: 'common.white', textAlign: 'center' }}>
              Utilise these and many other Conversion APIs without writing a single line of code.
            </Typography>

          </Stack>
          <Stack sx={{ position: 'relative' }}>
            <CarouselArrows
              onNext={handleNext}
              onPrevious={handlePrevious}
              sx={{
                '& .arrow': {
                  mt: -2.5,
                  '& button': {
                    mx: { xs: -0.5, md: -1.5 },
                    opacity: 1,
                    color: 'common.white',
                    bgcolor: 'accent.main',
                    '& svg': { width: 18, height: 18 },
                    '&:hover': {
                      opacity: 1,
                      bgcolor: 'accent.main',
                      color: 'common.white',
                    },
                  },
                },
              }}
            >
              <Slider ref={carouselRef} {...carouselSettings}>
                {connectors.map((connector) => (
                  <ConnectorItem key={connector.value} connector={connector} />
                ))}
              </Slider>
            </CarouselArrows>
          </Stack>
        </Stack>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

ConnectorItem.propTypes = {
  connector: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }),
};

function ConnectorItem({ connector }) {
  const signup = useSignupContext();

  const { name, value } = connector;

  return (
    <Stack sx={{ bgcolor: 'background.default', p: 2, pt: 3, m: 1, borderRadius: 1, height: '100%' }} spacing={1} justifyContent='center'
           alignItems='center'>
      <Stack>
        <SocialIcon alias={value} width={48} />
      </Stack>
      <Stack spacing={1.5}>
        <Typography color='inherit' variant='h4' sx={{ textAlign: 'center' }}>
          {name}
        </Typography>
        <Stack spacing={0.5}>
          <Button
            color='accent'
            variant='contained'
            endIcon={<Hidden implementation='css' smDown><Iconify icon='carbon:chevron-right' sx={{ ml: -0.5 }} width={16} /></Hidden>}
            onClick={() => signup.onToggle('register', '/start')}
            sx={{ pl: { xs: 1.5, md: 2 } }}
          >
            <Hidden implementation='css' smDown>Get Started Now</Hidden><Hidden implementation='css' smUp>Get Started</Hidden>
          </Button>
          <Button
            size='small'
            onClick={() => signup.onToggle('contact')}
            sx={{ textTransform: 'none' }}
          >
            Request a demo
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
